
.title[data-v-47318e3a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.title img[data-v-47318e3a] {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.title p[data-v-47318e3a] {
    margin: 0;
}
.title .title-msg[data-v-47318e3a] {
    font-weight: bold;
    font-size: 16px;
}

