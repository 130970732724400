
@-webkit-keyframes popver {
  0%{
    opacity: 0;
  }
  100%{
   opacity: 1;
  }
}

@keyframes popver {
  0%{
    opacity: 0;
  }
  100%{
   opacity: 1;
  }
}

@-webkit-keyframes popver-out {
  0%{
    opacity: 1;
  }
  100%{
   opacity: 0;
  }
}

@keyframes popver-out {
  0%{
    opacity: 1;
  }
  100%{
   opacity: 0;
  }
}

@-webkit-keyframes ai-img-1 {
  16%{
    left: -80px;
    top: -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left: -80px;
    top: -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@keyframes ai-img-1 {
  16%{
    left: -80px;
    top: -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left: -80px;
    top: -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@-webkit-keyframes ai-img-2 {
  16%{
    left: -80px;
    botom:  -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left:  -80px;
    botom:  -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@keyframes ai-img-2 {
  16%{
    left: -80px;
    botom:  -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left:  -80px;
    botom:  -100px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@-webkit-keyframes ai-img-3 {
  16%{
    left: 30px;
    top: -70px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left: 30px;
    top: -70px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@keyframes ai-img-3 {
  16%{
    left: 30px;
    top: -70px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left: 30px;
    top: -70px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@-webkit-keyframes ai-img-4 {
  16%{
    left: 38px;
    botom: -120px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left: 38px;
    botom: -120px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@keyframes ai-img-4 {
  16%{
    left: 38px;
    botom: -120px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  84%{
    left: 38px;
    botom: -120px;
    -webkit-transform: scale(1);
            transform: scale(1)
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0)
  }
}

@-webkit-keyframes ai-img-1-1 {
  10%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  18%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  26%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  60%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  68%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  76%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}

@keyframes ai-img-1-1 {
  10%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  18%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  26%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  60%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  68%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  76%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}

@-webkit-keyframes ai-img-2-2 {
  6%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  14%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  22%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  54%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  62%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  70%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}

@keyframes ai-img-2-2 {
  6%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  14%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  22%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  54%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  62%{
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg)
  }
  70%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}

@-webkit-keyframes ai-img-3-3 {
  8%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  16%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  24%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  58%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  62%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  70%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}

@keyframes ai-img-3-3 {
  8%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  16%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  24%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  58%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  62%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  70%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}

@-webkit-keyframes ai-img-4-4 {
  10%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  18%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  26%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  60%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  68%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  76%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}

@keyframes ai-img-4-4 {
  10%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  18%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  26%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }

  60%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
  68%{
    -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg)
  }
  76%{
    -webkit-transform: rotate(0);
            transform: rotate(0)
  }
}
