
.app-code[data-v-0b5f9bf4]{
  position:absolute;
  right:-22px;
  top:35px;
  z-index:999999;
  width: 200px;
  height: 200px;
}
button[data-v-0b5f9bf4] {
  color: #fff;
  border: none;
}
.line[data-v-0b5f9bf4]{
  width: 20px;
  height: 1px;
  background: #cccccc;
  display:block;
  position: relative;
  left: 15px;
  margin: 12px 0;
}
.out[data-v-0b5f9bf4] {
  color: #555!important;
  border: 1px solid #ddd!important;
}
.userInfo[data-v-0b5f9bf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tel[data-v-0b5f9bf4] {
  margin:0 10px;
  cursor: pointer;
  max-width: 70px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.header[data-v-0b5f9bf4] {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: #fff;
  /* transition: all .5s; */
}
.header .logo img[data-v-0b5f9bf4], .header2 .logo img[data-v-0b5f9bf4] {
  -o-object-fit: contain;
     object-fit: contain;
}
.wechatBtn[data-v-0b5f9bf4] {
  cursor: pointer;
  margin-bottom: 10px;
  width: 352px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #32D058;
  border-radius: 4px;
}
/* .wechatBtn img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
} */
.wechatBtn p[data-v-0b5f9bf4] {
  margin: 0;
  color: #fff;
}
.header2[data-v-0b5f9bf4] {
  width: 1200px;
  margin: auto;
  position: fixed;
  left: 0;
  top: 20px;
  right: 0;
  z-index: 99999;
  -webkit-transition: all .5s;
  transition: all .5s;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #eee;
}
.header2 .logo[data-v-0b5f9bf4]{
  margin-left: 20px;
}
.header2 .topLine[data-v-0b5f9bf4] {
  display: none;
}
.header3[data-v-0b5f9bf4] {
  width: 100%;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99999;
  -webkit-transition: all .5s;
  transition: all .5s;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #eee;
  top: 68px!important;
}
.topLine[data-v-0b5f9bf4] {
  height: 4px;
}
.botLine[data-v-0b5f9bf4] {
  height: 1px;
  background: #eee;
}
#nav[data-v-0b5f9bf4] {
  width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 0;
}
.menu .item[data-v-0b5f9bf4] {
  margin: 0 12px;
  display: block;
  font-size: 16px;
  line-height: 16px;
  padding: 5px 0;
  height: 28px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.logo[data-v-0b5f9bf4] {
  width: 140px;
  height: 50px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
}
.logo img[data-v-0b5f9bf4] {
  -o-object-fit: contain;
     object-fit: contain;
}
.menu[data-v-0b5f9bf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.login[data-v-0b5f9bf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.login span[data-v-0b5f9bf4] {
  font-size: 14px;
}
.login img[data-v-0b5f9bf4] {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}
.loginForm[data-v-0b5f9bf4] {
  width: 100%;
  padding: 30px 0 10px;
}
.loginForm input[data-v-0b5f9bf4] {
  margin-bottom: 20px;
  font-size: 14px;
}
.checkWrap[data-v-0b5f9bf4] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.loginBtn[data-v-0b5f9bf4] {
  margin-bottom: 15px;
  font-size: 14px;
}
.register[data-v-0b5f9bf4] {
  cursor: pointer;
}
.photo[data-v-0b5f9bf4] {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
}
.anticon[data-v-0b5f9bf4] {
  display: none;
}
.drawer .item[data-v-0b5f9bf4] {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}
.qrcodeTitle[data-v-0b5f9bf4] {
  cursor: pointer;
  color: #333;
}
.sideDo[data-v-0b5f9bf4] {
  position: fixed;
  right: 20px;
  bottom: 30%;
  width: 50px;
  border-radius: 6px;
  /* 注释原因：百家云直播 */
  z-index: 999999999;
  background-color: #ffffff !important;
  padding: 16px 0;
  -webkit-box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
}
.left_box[data-v-0b5f9bf4]{
  height: 226px;
  opacity: 0.9;
}
.sideDo ul[data-v-0b5f9bf4] {
  position: relative;
}
.sideDo ul li[data-v-0b5f9bf4] {
  /* padding: 10px 0; */
  cursor: pointer;
  text-align: center;
}
.sideDo ul li  img[data-v-0b5f9bf4] {
  /* width: 18px;
  height: 18px; */
}
.side-code-bg[data-v-0b5f9bf4] {
  width: 180px;
  padding: 10px!important;
  background: #fff;
  /* border-radius: 6px; */
  position: absolute;
  left: -180px;
  top: -15px;
  border: 1px solid #eee;
}
.sideDo .codeTxt[data-v-0b5f9bf4] {
  margin: 0;
  font-size: 14px;
  padding-top: 5px;
  font-weight: bold;
}
.side-code-bg img[data-v-0b5f9bf4]{
  width: 100%!important;
  /* height: 100%!important; */
}
.serve-wrap[data-v-0b5f9bf4] {
  width: 180px;
  padding-top: 10px!important;
  background: #fff;
  /* border-radius: 6px; */
  position: absolute;
  left: -180px;
  top: -15px;
  border: 1px solid #eee;
}
.serve-wrap h3[data-v-0b5f9bf4] {
  font-size: 18px;
  margin: 0;
  /* color:rgb(0, 113, 245); */
  color: #3D3D3D;
}
.loginItem[data-v-0b5f9bf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}
.loginItem label[data-v-0b5f9bf4] {
  min-width: 90px;
}
.loginItem input[data-v-0b5f9bf4] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 0;
  height: 40px;
  font-size: 14px;
}
.loginForm button[data-v-0b5f9bf4] {
  min-width: 102px;
  font-size: 14px;
}
.do-wrap[data-v-0b5f9bf4] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.forget-title[data-v-0b5f9bf4] {
  padding: 15px 0;
}
.phone-wrap[data-v-0b5f9bf4], .code-wrap[data-v-0b5f9bf4] {
  width: 100%;
}
.forget-btn[data-v-0b5f9bf4] {
  height: 40px;
  width: 100%;
}
.forget-msg[data-v-0b5f9bf4] {
  padding: 10px 0;
}
.forget-msg span[data-v-0b5f9bf4] {
  font-size: 12px;
  color: #666;
}
.go-do[data-v-0b5f9bf4] {
  padding: 10px 0;
  cursor: pointer;
}
.go-do span[data-v-0b5f9bf4] {
  text-align: center;
  display: block;
  color: rgb(0, 113, 245);
}
.code-title[data-v-0b5f9bf4] {
  text-align: center;
}

/* 
  模板1移动端适配css 
*/
/* 
@media screen and (max-width: 1024px){
  .sideDo {
    display: none;
  }
  .wechatBtn {
    display: none
  }
  .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    background: #fff;
    border: none;
    border-radius: 0;
  }
  .header2 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    background: #fff;
    border: none;
    border-radius: 0;
  }
  .header2 .logo{
    margin-left: 0;
  }
  .header2 .topLine {
    display: block;
  }
  #nav {
    width: 100%;
    padding: 10px;
  }
  .menu {
    display: none;
  }
  .anticon {
    display: block;
    font-size: 30px;
  }
  .drawerMenu .item{
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .tel {
    margin:0 5px;
  }
  .logo {
    width: 120px;
    height: 34px;
  }
  .qrcodeTitle {
    display: none;
  }
}
*/

/* register */
button[data-v-0b5f9bf4] {
    color: #fff;
    border: 1px solid #fff;
}
.reset[data-v-0b5f9bf4] {
    color: #333!important;
    border: 1px solid #ddd!important;
    margin-left: 20px;
}
.register .form[data-v-0b5f9bf4] {
    /* width: 600px; */
    border-radius: 6px;
    height: 100%;
    margin: 0 auto;
    /* padding:20px 0 0; */
    /* box-shadow: 1px 1px 3px #aaa; */
}
.register .title[data-v-0b5f9bf4] {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    color: rgb(42, 42, 42);
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 30px;
}
.register .formItem[data-v-0b5f9bf4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 18px;
}
.register .formItem .codeNumber[data-v-0b5f9bf4] {
    width: 98px;
}
.register .formWrap[data-v-0b5f9bf4] {
    width: 300px;
    margin: 0 auto;
}
.register .formItem label[data-v-0b5f9bf4] {
    height: 40px;
    line-height: 40px;
    min-width: 80px;
    text-align: left;
    margin-right: 10px;
}
.register .formItem input[data-v-0b5f9bf4] {
    /* width: 260px; */
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 40px;
    line-height: 40px;
    padding: 0 11px
}
.register .formItem .select-wrap[data-v-0b5f9bf4] {
  width: 100%;
}
.sendBtn[data-v-0b5f9bf4] {
    margin-left: 10px;
    font-size: 14px;
    min-width: 102px;
}
.register .btnWrap[data-v-0b5f9bf4] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
}
.register .checkWrap label[data-v-0b5f9bf4] {
    margin-right: 0;
}
.artical[data-v-0b5f9bf4]{
    text-align: left;
    width: 100%;
    text-indent: 2em;
    height: 70vh;
    overflow: auto;
}
.artical h3[data-v-0b5f9bf4] {
    font-size: 14px;
    font-weight: bold;
}
.artical ul li[data-v-0b5f9bf4] {
    list-style: outside;
    text-indent: 4em;
}
#captchaBoxBtn[data-v-0b5f9bf4] {
    width: 260px;
}


/*
  template-two
*/
.tem-two-top-img[data-v-0b5f9bf4] {
  width: 1200px;
  height: 120px;
  margin: 0 auto;
}
.tem-two-top-img img[data-v-0b5f9bf4] {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.tem-two-nav[data-v-0b5f9bf4] {
  width: 100%;
  height: 50px;
  /* border-radius: 4px; */
}
.tem-1x[data-v-0b5f9bf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 540px;
  background: url(../img/1xbanner.7cd5d22b.png) no-repeat center center;
  background-size: cover;
  padding-top: 10px;
}
.tem-1x-unIndex[data-v-0b5f9bf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.button1x[data-v-0b5f9bf4] {
  width: 130px;
  height: 46px;
  border-radius: 23px;
  background: #37B979;
  color: #fff;
  border: none;
  margin-bottom: 95px;
}
.tem-1x-nav-inner[data-v-0b5f9bf4] {
  height: 80px;
  width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tem-two-nav-inner[data-v-0b5f9bf4] {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tem-two-nav-menu-wrap[data-v-0b5f9bf4] {
 
  display: -webkit-box;
 
  display: -ms-flexbox;
 
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position:relative;
}
.tem-two-nav-item[data-v-0b5f9bf4] {
  margin-right: 40px;
  color: #fff;
  font-size: 16px;
  line-height: 32px;
  border-bottom: 2px solid #0880F1;
  cursor: pointer;
  text-shadow: 0 1px 1px rgba(0,0,0,0.5);
}
/* .tem-two-nav-item:hover{
  border-bottom: 2px solid #ffffff !important;
} */
.tem-1x-item[data-v-0b5f9bf4] {
  margin-right: 40px;
  color: #91bbe4;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
}
.tem-1x-unItem[data-v-0b5f9bf4] {
  margin-right: 40px;
  color: #999;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
}
.tem-two-header[data-v-0b5f9bf4] {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  height: 50px!important;
}
.tem-two-wechat-login[data-v-0b5f9bf4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tem-two-wechat-login .tem-two-login-line-wrap[data-v-0b5f9bf4] {
  width: 100%;
  color: #AEB3C0;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 0 25px;
}
.tem-two-wechat-login img[data-v-0b5f9bf4]{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.tem-two-wechat-login .tem-two-login-line[data-v-0b5f9bf4] {
  width: 60px;
  height: 1px;
  background: #AEB3C0;
}
.tem-two-wechat-login .tem-two-login-line-name[data-v-0b5f9bf4] {
  padding: 0 10px;
}
.cap-style-tem-one[data-v-0b5f9bf4] {
  background: #0071F5;
  color: #fff;
  border-color: #fff;
  height: 40px;
}
.cap-style-tem-two[data-v-0b5f9bf4] {
  background: #fff;
  color: #0880F1;
  border-color: #0880F1;
  height: 40px;
}
#sc[data-v-0b5f9bf4] {
  margin: 0;
}
.nc_scale[data-v-0b5f9bf4] {
  background: #e8e8e8 !important;
}
.nc_scale div.bc_bg[data-v-0b5f9bf4] {
  background: #7ac23c !important;
}
.nc_scale .scale_text2[data-v-0b5f9bf4] {
  color: #fff !important;
}
.nc_scale span[data-v-0b5f9bf4] {
  border: 1px solid #ccc !important;
}
.errloading[data-v-0b5f9bf4] {
  border: #faf1d5 1px solid !important;
  color: #ef9f06 !important;
}
