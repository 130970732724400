
.footer[data-v-51bdc71a] {
    height: 300px;
    padding-top: 40px;
    background: #333;
    color: #fff;
}
.footer .item[data-v-51bdc71a] {
    text-align: left;
}
.inner[data-v-51bdc71a] {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.bot-left[data-v-51bdc71a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.title[data-v-51bdc71a] {
    font-size: 12px;
    margin-bottom: 22px;
    color: #999;
    line-height: 12px;
    text-align: left;
}
.title2[data-v-51bdc71a] {
    font-size: 12px;
    margin-bottom: 10px;
    color: #CBCBCB;
    line-height: 12px;
    text-align: left;
}
.subt[data-v-51bdc71a] {
    font-size: 12px;
    color: #5F5F5F;
    line-height: 12px;
    display: block;
}
.menus li[data-v-51bdc71a] {
    margin-bottom: 20px;
}
.line-wrap[data-v-51bdc71a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    padding-bottom: 30px;
    margin: 0 30px;
}
.line[data-v-51bdc71a] {
    width: 1px;
    background: #5F5F5F;
    height: 136px;
}
.bot-right[data-v-51bdc71a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 24px;
}
.txtStyle[data-v-51bdc71a] {
    color: #999;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 12px;
    text-align: left;
}
.code-size[data-v-51bdc71a] {
    width: 78px;
    height: 78px;
    margin-bottom: 15px;
}
.code-wrap[data-v-51bdc71a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.code-item[data-v-51bdc71a] {
    margin-right: 20px;
}
.bot-wrap[data-v-51bdc71a] {
    height: 100px;
    background: #222;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
