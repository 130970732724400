
.footer .bot[data-v-62fe346e] {
  height: 40px;
  line-height: 40px;
}
.footer a[data-v-62fe346e] {
  color: #fff;
}
.footer .icon[data-v-62fe346e] {
  margin-right: 10px;
}
.footer .item[data-v-62fe346e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer .item span[data-v-62fe346e] {
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
}
.footer .logo[data-v-62fe346e] {
  width: 120px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
}
.footer .inner[data-v-62fe346e] {
  width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 150px;
}
.footer .top[data-v-62fe346e] {
  background: #F7F9FC;
}
@media screen and (max-width: 1024px) {
.footer .inner[data-v-62fe346e] {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100px;
}
.footer .logo[data-v-62fe346e] {
    display: none;
}
}
