
.face-wrap[data-v-8013c138] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 362px;
}
.face-msg[data-v-8013c138] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.face-img[data-v-8013c138] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  padding-top: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.face-img .img-wrap[data-v-8013c138] {
    width: 200px;
    height: 200px;
    background: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.face-img img[data-v-8013c138] {
    width: 128px;
    height: 128px;
}
.face-msg .msg-wrap[data-v-8013c138] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}
.face-msg .msg-wrap img[data-v-8013c138]{
    width: 24px;
    height:24px;
    margin-right: 10px;
}
.face-msg .title[data-v-8013c138] {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.face-msg .sub-text[data-v-8013c138] {
    font-size: 12px;
    background: #efefef;
    padding: 10px 15px;
    border-radius: 4px;
    color: #888;
    margin-bottom: 20px;
}
.do-msg .title[data-v-8013c138] {
    margin-bottom: 10px;
}
.do-msg .icon-item[data-v-8013c138] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}
.do-msg .icon-item p[data-v-8013c138] {
    font-size: 12px;
    margin-bottom: 0;
    background: #efefef;
    color: #444;
    padding: 0 10px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.do-msg .icon-wrap[data-v-8013c138] {
    width: 40px;
    height: 40px;
    background: #0880F1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.do-msg .icon-wrap img[data-v-8013c138] {
    width: 28px;
    height: 28px;
}
.btn[data-v-8013c138] {
    color: #fff;
    width: 140px!important;
    height: 36px!important;
    margin-bottom: 10px;
    border: none;
}
.red[data-v-8013c138] {
    color: #c00;
    font-weight: bold;
}
.face-cancel-warn[data-v-8013c138] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}
.face-cancel-warn img[data-v-8013c138] {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.face-cancel-warn .warn-text[data-v-8013c138] {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
}
.warn-sub[data-v-8013c138] {
    margin-bottom: 0;
}
