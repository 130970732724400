
.footer-box[data-v-9a9b4b8c]{
    background-image: url(../img/main_bottom.b96260c1.png);
    background-repeat: no-repeat;
}
.footerImage[data-v-9a9b4b8c] {
    width: 100%;
    height: 362px;
    /* position: absolute;
    left: 0px;
    bottom: 0px; */
    /* height: 362px;
    background-image: url(../../assets/images/main_bottom.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover; */
}
.footer[data-v-9a9b4b8c]{   
    position: relative;
    margin-top: 200px;
    /* top: -160px; */
}
.bot-wrap[data-v-9a9b4b8c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 10px;
}
.footer .bot[data-v-9a9b4b8c] {
  height: 40px;
  line-height: 40px;
  background: #E3E3E3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer .bot a[data-v-9a9b4b8c] {
    color: #7B7B7B;
    font-size: 12px;
}
.footer-content[data-v-9a9b4b8c] {
    height: 266px;
    background: #333;
    /* box-shadow: 0 -2px 2px #eee!important; */
}
.inner[data-v-9a9b4b8c] {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.f-l[data-v-9a9b4b8c] {
    -webkit-box-flex: 4;
        -ms-flex: 4;
            flex: 4;
}
.f-r[data-v-9a9b4b8c] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.f-l ul[data-v-9a9b4b8c] {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 13px;
}
.f-l ul li[data-v-9a9b4b8c] {
    cursor: pointer;
    color: #ccc;
    font-size: 12px;
}
.f-l .logo img[data-v-9a9b4b8c] {
    width:100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.bottom-text[data-v-9a9b4b8c] {
    height: 78px;
    font-size: 12px;
    font-weight: normal;
    line-height: 26px;
    color: #808080;
}
.f-l .logo[data-v-9a9b4b8c] {
    width: 148px;
    height: 66px;
    margin-bottom: 10px;
}
.f-l .inc-name[data-v-9a9b4b8c], .f-l .email[data-v-9a9b4b8c], .f-l .tel[data-v-9a9b4b8c]{
    text-align: left;
    color: #333;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 20px;
}
.f-r img[data-v-9a9b4b8c] {
    width: 156px;
    height: 156px;
    margin-bottom: 20px;
    border-radius: 50%;
}
.f-r p[data-v-9a9b4b8c] {
    font-size: 30px;
    color: #333;
    margin-bottom: 0;
}
.call-wrap[data-v-9a9b4b8c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
